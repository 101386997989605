import { Stack } from '@mui/material';
import { InView } from 'react-intersection-observer';
import type { FeatureToggledNextLinkProps } from '@aph/components/common/feature-toggled-next-link';
import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import type { ContentfulImageProps } from '../next-contentful-image/next-contentful-image';
import { ContentfulImage } from '../next-contentful-image/next-contentful-image';

export type ImageLinkProps = ContentfulImageProps &
  Pick<FeatureToggledNextLinkProps, 'href'> & {
    onInView?: () => void;
    onClick?: () => void;
  };

export const ImageLink = ({ onInView, onClick, href, ...props }: ImageLinkProps) => (
  <InView
    as="div"
    threshold={1}
    triggerOnce
    onChange={(inView) => inView && onInView && onInView()}
    style={{ height: '100%' }}
  >
    <FeatureToggledNextLink height="100%" href={href} onClick={onClick}>
      <Stack height="100%" width="100%" overflow="hidden" borderRadius={{ xs: 4, sm: 6 }}>
        <ContentfulImage
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
          }}
          {...props}
        />
      </Stack>
    </FeatureToggledNextLink>
  </InView>
);
