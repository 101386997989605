import React from 'react';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { IImageLink } from '~/contentful/types';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { ArticleCard, type ArticleCardProps } from './article-card/article-card';
import { ArticleCardSkeleton } from './article-card/article-card-skeleton';
import { BannerCard } from './banner-card/banner-card.component';

export type ArticleGridProps = {
  listName?: ArticleCardProps['listName'];
  articles?: ReadonlyArray<ArticleCardProps['article']>;
  isLoadingNext?: boolean;
  isLoadingPrevious?: boolean;
  totalCount?: number;
  isFetching?: boolean;
  banner?: IImageLink | undefined;
};

export const ArticleGrid = ({
  listName,
  articles = [],
  isLoadingNext = false,
  isLoadingPrevious = false,
  isFetching = false,
  totalCount = 0,
  banner,
}: ArticleGridProps) => {
  const amountToLoad = Math.min(
    (totalCount === 0 ? DEFAULT_PAGE_SIZE : totalCount) - articles.length,
    DEFAULT_PAGE_SIZE,
  );
  const renderLoader = () =>
    [...Array(amountToLoad).keys()].map((i) => <ArticleCardSkeleton key={i} />);

  return (
    <div
      className={cn(
        'grid w-full grid-cols-2 gap-x-1 gap-y-4 sm:grid-cols-3 sm:gap-x-2 sm:gap-y-5 lg:grid-cols-4 xl:grid-cols-6',
        {
          'blur-[2px]': isFetching,
        },
      )}
    >
      {isLoadingPrevious && renderLoader()}
      {banner && <BannerCard banner={banner} />}
      {articles.map((article, index) => {
        if (listName) {
          return (
            <div
              key={article.articleCode}
              style={{
                '--order': index + 1,
              }}
              className="relative order-[var(--order)]"
            >
              {article.isSponsored && (
                <Typography
                  typography="subHeading"
                  className="absolute -top-[4px] left-0 -translate-y-full"
                  aria-hidden
                  asChild
                >
                  <span>Sponsrad</span>
                </Typography>
              )}
              <ArticleCard article={article} listName={listName} index={index} />
            </div>
          );
        }
        if (process.env.NODE_ENV === 'development') {
          throw Error('List name must be implemented for tracking purposes');
        }
        return null;
      })}
      {isLoadingNext && renderLoader()}
    </div>
  );
};
